import {post,get,put,del} from '../../utils/request'
/**
 * 创建文章
 */
export function createArticle(params){
    return post(
        '/article/createArticleApp',
        params
    )
}
/**
 * 通过微信url获取公众号文章
 */
export function getWxArticleByUrl(params){
    return post(
        '/article/getWxArticleByUrl',
        params
    )
}
/**
 * 获取文章列表
 */
export function getMyArticleList(params){
    return get(
        '/article/findMyArticleByPage',
        params,      
    )
}
/**
 * 创建文章前获取id
 */
export function findNextId(){
    return get(
        '/article/findNextId',
    )
}
/**
 * 获取文章列表前获取是否显示顶部一级选择框
 */
export function getMerchantManagementScope(){
    return get(
        '/user/getMerchantManagementScope',
    )
}
/**
 * 删除文章
 */
export function deleteArticle(id){
    return del(
       `/article/deleteArticleApp/${id}`,
    )
}
/**
 * 修改文章
 */
export function updateArticle(params){
    return put(
        '/article/updateArticleApp',
        params
    )
}
/**
 * 获取留言
 */
export function getArticleLeavingMsg(params){
    return get(
        '/article/findArticleLeavingMsgByPage',
        params
    )
}
/**
 * 增加文章浏览数,点赞数，收藏数
 */
export function addNum(id,category,openId){
    return put(
        `/article/addNum/${id}/${category}/${openId}`,
    )
}

/**
 * 减少文章浏览数,点赞数，收藏数
 */
 export function reduceNum(id,category,openId){
    return put(
        `/article/reduceNum/${id}/${category}/${openId}`,
    )
}

/**
 * 创建留言
 */
export function createArticleLeavingMsg(params){
    return post(
        '/article/createArticleLeavingMsg',
        params
    )
}
/**
 * 把文章变为当前用户
 */
export function isArticleBelongUser(id){
    return get(
       `/article/isArticleBelongUser/${id}`,
    )
}
/**
 * 获取留言
 */
export function getArticleDetailsUrl(articleId){
    return get(
        `/article/getArticleDetailsUrl/2/${articleId}`,
    )
}

