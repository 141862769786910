<template>
  <div class="visitorsArticlesDetails_conatiner">
    <header class="header" v-if="showHeader">
      <van-nav-bar
        title="文章"
        left-arrow
        @click-left="handleBlack"
        right-text="分享"
        @click-right="handleAtricle('share')"
        safe-area-inset-top
      >
      </van-nav-bar>
    </header>
    <div class="handle-article">
      <div @click="handleAtricle('editor')">
        <icon-svg iconClass="iconedit-3" class="editor" />编辑
      </div>
      <div @click="handleAtricle('share')">
        <icon-svg iconClass="iconshare-2" class="share" />分享
      </div>
    </div>

    <article class="article-details">

      <van-tabs :border="false" swipeable background="#fff" title-active-color="rgb(255, 110, 1)">

        <article class="article-details"></article>
        <van-tab title="文章详情">
          <!-- 标题 -->
          <div class="artcile-base-info">
            <div class="article_title">{{ article.title }}</div>
            <div class="article_createtime">{{ article.createTime }}</div>
          </div>
          <!-- 名片 -->
          <van-sticky :offset-top="topSpace">
            <van-skeleton title avatar :row="3" v-if="showSkeleton"></van-skeleton>
            <div v-else class="name-card">
              <div class="name-info" @click="loadUserCard">
                <img
                        :src="
                article.userCard.headImgUrl
                  ? `${imgUrl}/${article.userCard.headImgUrl}`
                  : morenHeadImg
              "
                        alt=""
                />
                <div class="creater-info">
                  <p>{{ article.userCard.name }}</p>
                  <p>{{ article.userCard.merchantName }}</p>
                </div>
              </div>
              <div class="synopsis">
                <span>{{ isFold ? foldSynopsisStr : synopsisStr }}</span>
                <span
                        style="color: #999"
                        @click="handleIsFold"
                        v-if="synopsisStr.length > 40"
                >{{ isFold ? "...展开" : "&lt;&lt;折叠" }}</span
                >
              </div>
            </div>
          </van-sticky>
          <!-- 封面 -->
          <!-- <div class="cover-img">
            <div class="img-box">
              <img :src="article.image" alt="" @click="loadCoverImage" />
            </div>
          </div> -->
          <!-- 详情 -->
          <div class="artcle-content">
            <div class="artcle-content-box">
              <transform-html :contents="article.contents"></transform-html>
            </div>
          </div>
          <div class="star-collection">
           <div class="left">
             <div class="star" @click="handleStarOrCollect('star')">
               <div>
                 <icon-svg
                     :iconClass="starShow ? 'iconstar1' : 'iconstar'"
                     class=""
                 />
               </div>
               {{ article.star }}
             </div>
             <div class="collection" @click="handleStarOrCollect('collect')">
               <div>
                 <icon-svg
                     :iconClass="collectShow ? 'iconGroup91' : 'iconthumbs-up'"
                     class=""
                 />
               </div>
               {{ article.collection }}
             </div>
           </div>
            <div class="leaving-botton-ts" v-if="!(wahereFrom=='0')">
              <button
                  :class="showCreteMsg ? 'button-bc' : ''"
                  @click="handleOpenLeavingMsg"
              >
                留言
              </button>
            </div>
          </div>



         <div v-if="!(wahereFrom=='0')">
           <div class="leave-message-box" v-for="it in msgList" :key="it.id">
             <div class="user-info">
               <div class="img-content">
                 <img :src="it.headImgUrl ? `${it.headImgUrl}` : morenHeadImg" alt=""/>
                 <div class="name-content">
                   <div class="name">{{ it.wetchatUsername }}</div>
                   <div class="content">{{ it.content }}</div>
                 </div>
               </div>

             </div>
             <div class="leave-message-time">
               <div style="display: flex;align-items: center">
                 <div style="width: 40px;">
                   <div v-if="it.hasSubReply==1" style="width: 40px;display: flex;align-items: center">
                     <van-icon name="arrow-up" class="icon-cls" @click="getLeavingMsgAllReply(it.id)" v-show="it.show"/>
                     <van-icon name="arrow-down" class="icon-cls"  @click="closeReply(it.id)" v-show="!it.show"/>
                   </div>
                 </div>
                 <span class="time"> {{ it.createTime}}</span><span class="reply" @click="handleReplyInfo(it)">回复</span>
               </div>
               <span style="color: red" @click="handleDeleteMsg(it)">删除</span>
             </div>

             <!--回复-->
             <div class="leave-message-box margin-cls" v-for="item in it.reply" :key="item.id">
               <div class="user-info">
                 <div class="img-content">
                   <img :src="item.headImgUrl ? `${item.headImgUrl}` : morenHeadImg" alt=""/>
                   <div class="name-content">
                     <div class="name">{{ item.wetchatUsername }}</div>
                     <div class="content">{{ "回复@"+item.parentWechatUserName +"："+item.content}}</div>
                   </div>
                 </div>

               </div>
               <div class="leave-message-time-child">
                 <div>
                   <span class="time"> {{ item.createDate }}</span><span class="reply" @click="handleReplyInfo(it,item)">回复</span>
                 </div>
                 <span style="color: red" @click="handleDeleteMsg(it,item)">删除</span>
               </div>
             </div>

           </div>
           <!--                  加载更多-->
           <div style="background-color: #FFFFFF;padding: 10px 0;" v-if="showMoreButton">
             <span v-if="showMore" style="font-size: 14px;color: #289fff" @click="loadMore">加载更多评论</span>
             <van-loading v-else color="#1989fa" >加载中...</van-loading>
           </div>
           <div style="background-color: #FFFFFF;padding: 10px 0;" v-else>
             <span v-if="showMore" style="font-size: 14px;color: #cccccc">没有更多了</span>
           </div>
         </div>


<!--          <div class="create-leave-message" v-if="showCreteMsg">-->
        </van-tab>
        <van-tab title="传播轨迹">
          <div class="related-clues">
            <p v-if="withClueList.length">关联线索</p>
            <van-list v-if="withClueList.length">
              <van-cell v-for="it in withClueList" :key="it">
                <div class="clues-box">
                  <div class="img-name">
                    <img
                            :src="it.headImgUrl ? it.headImgUrl : morenHeadImg"
                            alt=""
                    />
                    <div class="creater-info">
                      <p>
                        {{ it.name }}&nbsp;&nbsp;{{
                        it.phone
                        ? `${it.phone.slice(0, 3)}****${it.phone.slice(7)}`
                        : ""
                        }}
                      </p>
                      <!-- it.browseTime?`${it.browseTime.slice(0,10)}****${it.browseTime.slice(11,19)}`:'' -->
                    </div>
                  </div>
                  <div class="clues-details" @click="loadCuleSDetail(it.clueId)">
                    <p>浏览时间：{{ it.browseTime }}</p>
                    <p>浏览时长：{{ it.browseSecond }}秒</p>
                    <p>浏览位置：{{ it.clueShareSource }}</p>
                    <p>分享人：{{ it.createName }}</p>
                    <p>推荐人：{{it.recommend_Name}}</p>
                  </div>
                </div>
              </van-cell>
            </van-list>
            <div v-else class="no-clues">
              <img :src="nodataImg">
            </div>
          </div>
        </van-tab>
      </van-tabs>
     <div class="create-leave-message" v-if="showCreteMsg">
<!--      <div class="create-leave-message">-->
        <div class="name-card">
          <div class="name-info">
            <img :src="
                article.userCard.headImgUrl
                  ? `${imgUrl}/${article.userCard.headImgUrl}`
                  : morenHeadImg
              " alt=""/>
            <div class="creater-info">
              <p>{{ article.userCard.name }}</p>
            </div>
          </div>
          <div class="synopsis">
            <van-field
                v-model="leaveMsgParams.content"
                rows="3"
                autosize
                type="textarea"
                :placeholder="writeLeaveMsg"
                show-word-limit
            />
            <div class="cancel-save">
              <van-button
                  square
                  type="primary"
                  color="#FF6E01"
                  @click="saveLeaveMsg"
                  v-if="leaveMsgParams.content"
              >发送
              </van-button>
              <span @click="saveLeaveMsg('cancel')">取消</span>
            </div>
          </div>
        </div>
      </div>
    </article>
    <!-- 弹出分享框 -->
    <div class="share_box">
      <van-share-sheet
        v-model:show="showShare"
        title="分享"
        :options="shareOptions"
        cancel-text="取消"
        @select="onSelect"
        safe-area-inset-bottom
      >
      </van-share-sheet>
    </div>
<!--    分享海报-->
    <div class="wrapperPoster" id="saveposter" style="z-index: 9999" v-if="showOverlay">
      <div class="poster-details" v-if="showImg" ref="imageWrapper">
        <img :src="posterPic" crossOrigin="anonymous"/>
        <div class="text-qrcode">
          <div class="user-info-tag">
            <div class="user-info">
              <img :src="headImgUrl">
              <div class="left-text">
                <p>{{ userForm.name }}</p>
                <p>{{ userForm.merchantName }}</p>
              </div>
            </div>
            <div class="user-tag">
             <span class="user-tag-item" v-for="(it,index) in userTags" :key="index">{{ it }}</span>
            </div>
          </div>
          <div class="qrCodeUrl-all">
            <div class="qrCodeUrl-cls">
              <img v-if="qrCodeUrl" :src="qrCodeUrl"/>
            </div>
            <p>立即扫码</p>
            <p>了解更多内容</p>
          </div>
        </div>
      </div>
      <div v-else class="posterImg-hb">
        <img class="card-img" :src="imgSrc" ref="cardImgRef" />
      </div>
    </div>
  </div>
</template>
<script>
import {reactive, toRefs, computed, ref, nextTick, watch} from "vue";
import { useRoute, useRouter } from "vue-router";
import {getUserCardByPoster} from "@/api/user/userCard"
import { reprint,createPosterAppShare,getQrCodeUrl } from "@/api/common";
import transformHtml from "../../components/transformHTML.vue";
import { getArticleById } from "../../api/articleManagement";
import { searchFileList,findPosterImg } from "../../api/file";
import { flutterShowToast } from "../../utils/appChannel";
import {ImagePreview, Toast,Dialog} from "vant";
import {createArticleLeavingMsg, getArticleDetailsUrl} from "../../api/huokeArticle";
import {findArticleLeavingMsgByPage,findLeavingMsgAllReply,deleteLeavingMsgReply} from "../../api/weChat";

import { copyStr,loadImages } from "@/utils/commonUtil";
import html2canvas from "html2canvas";
import {posterShare} from "@/utils/posterShare"
export default {
  components: {
    transformHtml,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const container = ref(null);
    const imageWrapper = ref(null);
    const cardImgRef = ref(null);
    const state = reactive({
      wahereFrom:'',
      writeLeaveMsg:'发表留言/回复',
      leaveMsgParams: {
        content: "",
      },
      showCreteMsg:false,
      msgList:[],
      childParentId:'',
      isChild:false,
      parentId:'',
      showToTop:true,
      showMoreButton:false,
      showMore:true,
      current:1,
      userForm:{},
      userTags:[],
      headImgUrl:'',
      nodataImg:require("../../assets/images/no-data.png"),
      showSharePoster:false,
      shareJumpUrl:'',
      posterId:'',
      imgSrc:'',
      qrCodeUrl:'',
      posterName:'',
      posterPic:'',
      sharePosterImg:'',
      showOverlay: false,
      showImg: true,
      shareOptions:[],
      defaultImg: require("@/assets/images/article-defaultImg-big.png"),
      //=======================分享海报
      showSkeleton:true,
      starShow: false,
      collectShow: false,
      topSpace: 43,
      hotArticles: {},
      coverImage: [],
      articleId: route.query.id,
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      morenImg: require("../../assets/images/article-defaultimg-small.png"),
      morenHeadImg: require("../../assets/icons/Vector.png"),
      showShare: false,
      withClueList: {},
      article: {
        nameCard: "",
        title: "",
        contents: "",
        createTime: null,
        star: null,
        collection: null,
        image: "",
        userCard: {},
        clueList: {},
        smallCoverPic: "",
      },
      fileParams: {
        busiId: route.query.id,
        busiCat: "article",
        groupId: "articleIndexPic",
      },
      options: [
        { name: "微信", icon: require("../../assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("../../assets/icons/friends2.png") },
        { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
        {name: "分享海报", icon: require("@/assets/icons/share_poster.png") },
      ],
      posterOptions: [
        {name: "微信分享", icon: require("@/assets/icons/weChat2.png")},
        {name: "朋友圈分享", icon: require("@/assets/icons/friends2.png")},
      ],
      synopsisStr: "",
      foldSynopsisStr: "",
      isFold: true,
    });
    watch(() => state.showShare, (newVal, oldVal) => {
      console.log('新值：',newVal,'老值',oldVal)
      if (state.showShare===false){
        state.showOverlay = false
        state.showImg = true
      }
    })
    let showHeader = computed(() => route.query.hideTitleBar != 1);
    if (!showHeader.value) {
      state.topSpace = 0;
    }
    //打开留言
    const handleOpenLeavingMsg = () => {
      state.showCreteMsg = !state.showCreteMsg;
      state.writeLeaveMsg = '发表您的留言'
      console.log("留言按钮");
    };
    //创建留言
    const saveLeaveMsg = (msg) => {
      state.showCreteMsg = !state.showCreteMsg;
      if (msg === "cancel") {
        state.leaveMsgParams.content = "";
        state.parentId = ""
        state.isChild = false
      } else {
        if (state.leaveMsgParams !== "") {
          let parentId = ""
          if (state.isChild){
            parentId = state.childParentId
          }else {
            parentId = state.parentId
          }
          let param = {
            headImgUrl: state.article.userCard.headImgUrl?`${state.imgUrl}/${state.article.userCard.headImgUrl}`:"",
            articleId: state.articleId,
            wetchatUsername: state.article.userCard.name,
            content: state.leaveMsgParams.content,
            parentId:parentId,
          };
          createArticleLeavingMsg(param).then(result=>{
            if (result){
              console.log("留言成功", result);
              Toast.success("您的留言已创建");
              if (state.parentId){
                getLeavingMsgAllReply(state.parentId)
              }else {
                state.current = 1
                getArticleLeavingMsgByPage()
              }
              state.parentId = ""
              state.isChild = false
              state.leaveMsgParams.content = "";
              // loadArticleDetails();
            }
          });


        }
      }
    };

    const handleReplyInfo=(it,child) =>{
      state.showCreteMsg = true
      state.parentId = it.id
      if (child){
        state.isChild = true
        state.childParentId = child.id
        state.writeLeaveMsg = `@${child.parentWechatUserName}`
      }else {
        state.writeLeaveMsg = `@${it.wetchatUsername}`
      }
      // console.log(it)
      console.log('一级======================',it,child)

    }

    //删除评论
    const handleDeleteMsg = (it,item)=>{
      console.log(it,item)
      let id = ""
      if (item){
        id = item.id
      }else {
        id = it.id
      }

      Dialog.confirm({
        message:
            '确定删除此评论？',
      })
          .then(() => {
            deleteLeavingMsgReply(id).then(res=>{
              if (res){
                if (item){
                  getLeavingMsgAllReply(it.id)
                }else {
                  state.msgList.map((it2,index)=>{
                    if (it2.id===it.id){
                      state.msgList.splice(index, 1);
                    }
                  })
                }
              }
            })
          })
          .catch(() => {
            // on cancel
          });
    }
    const loadMore = ()=>{
      state.showMore = false
      state.current = state.current + 1
      getArticleLeavingMsgByPage()
    }
    const closeReply = (id)=>{
      state.msgList.map(it=>{
        if (it.id===id){
          it.reply = []
          it.show = true
        }
      })
      state.showToTop = true
    }
    //加载回复
    const getLeavingMsgAllReply = (id) =>{
      findLeavingMsgAllReply({parentId:id}).then(res=>{
        console.log("子回复============",res)
        let reply = JSON.parse(JSON.stringify(res))
        reply.map(it=>{
          it.isChild = true
        })
        state.msgList.map(it=>{
          if (it.id===id){
            it.reply = reply
            it.show = false
            it.hasSubReply = 1
          }
        })
      })
    }
    const getArticleLeavingMsgByPage = async () =>{
      let p = {
        current: state.current,
        articleId:state.articleId,
        size:5
      }
      let res = await findArticleLeavingMsgByPage(p)
      if (res){
        let morelist = res.records
        morelist.map(it=>{
          it.reply = []
          it.show = true
        })
        if (state.current===1){
          state.msgList = []
          state.msgList.push(...morelist)
        }else {
          state.msgList.push(...morelist)
        }
        if (res.total>5){
          state.showMoreButton = true
        }
        if(res.total===state.msgList.length){
          state.showMoreButton = false
        }
        state.showMore = true
      }
      console.log("留言信息",res)
    }
    getArticleLeavingMsgByPage()

    const loadArticle = async () => {
      state.wahereFrom = route.query.myType
      console.log("从哪里来===========",state.wahereFrom)
      let data = await getArticleById(state.articleId);
      console.log("详情", data);
      // state.posterPic = data.headImgUrl?state.imgUrl+"/"+data.headImgUrl:state.defaultImg
      // state.sharePosterImg = data.headImgUrl?state.imgUrl+"/"+data.smallCoverPic:"https://wca.ujiaku.com/upload/app_defaultImg/article-default.png"
      state.posterName = data.title
      state.article.title = data.title;
      state.article.createTime = data.createTime
        ? data.createTime.slice(0, 10)
        : "";
      state.article.contents = data.content;
      state.article.star = data.countOfLikes;
      state.article.collection = data.countOfCollection;
      state.article.userCard = data.userCard;
      state.article.clueList = data.clueList;
      state.article.headImgUrl = data.headImgUrl;
      state.article.smallCoverPic = data.smallCoverPic;
      state.withClueList = data.clueList;
      state.article.jumpApplets = data.jumpApplets;
      state.synopsisStr = data.userCard.selfIntro?data.userCard.selfIntro:'我坚信细节决定品质，专业决定价值！如有需求，请随时与我联系！';
      state.foldSynopsisStr = state.synopsisStr.substring(0, 44);
      state.showSkeleton = false

      const p = {
        busiId:state.articleId,
        busiCat:'poster',
        groupId:'sharePosterPic',
      }
      findPosterImg(p).then((res)=>{
        if (res.length>0){
          //获取小图
          findPosterImg({
            busiId:state.articleId,
            busiCat:'poster',
            groupId:'sharePosterPic:S',
          }).then((sImg)=>{
            state.sharePosterImg = sImg[0].url?state.imgUrl+"/"+sImg[sImg.length-1].url:"https://wca.ujiaku.com/upload/app_defaultImg/article-default.png"
          })
          state.posterPic = res[0].url?state.imgUrl+"/"+ res[0].url:state.defaultImg
        }else {
          state.posterPic = state.defaultImg
          state.sharePosterImg = "https://wca.ujiaku.com/upload/app_defaultImg/article-default.png"
        }
      })
    };

    loadArticle();
    //线索跳转详情
    const loadCuleSDetail = (id) => {
      let params = {
        shareType: "openCustomPage",
        pageName: "clue_detail_page",
        param:{ clueId: id},
      };
      flutterShowToast(params);
    };
    //预览封面图
    const loadCoverImage = () => {
      ImagePreview({
        images: state.coverImage,
        showIndex: false,
      });
    };
    //展开折叠
    const handleIsFold = () => {
      state.isFold = !state.isFold;
    };
    //个人名片
    const loadUserCard = () => {
      router.push(`/userCard`);
    };
    //点赞或者收藏
    const handleStarOrCollect = (Str) => {
      if (Str === "star") {
        if (state.starShow === false) {
          state.starShow = true;
          state.article.star++;
        } else {
          state.starShow = false;
          state.article.star--;
        }
      } else if (Str === "collect") {
        if (state.collectShow === false) {
          state.collectShow = true;
          state.article.collection++;
        } else {
          state.collectShow = false;
          state.article.collection--;
        }
      }
    };
    const loadPoster = ()=>{
      savePoster()
      setTimeout(()=>{
        Toast.clear()
      })
    }
    //微信
    const onSelect = (index) => {
      if(index.name === "微信分享"||index.name === "朋友圈分享"){
        if(state.showSharePoster===true){
          Toast.loading({
            message: '正在\n打开微信...',
            forbidClick: true,
            duration:3000,
            className:'posterToast',
          });
          let p = {
            sourceId: state.articleId,//分享的id
            sourceType:1,//来源类别 1-文章 2-案例 3-视频 4-工地
            jumpUrl:state.shareJumpUrl
          }
          createPosterAppShare(p).then((res)=>{
            console.log("创建二海报",res)
          })
          let param = {
            imgSrc:state.imgSrc,
            id:state.articleId,
            bigPosterImgUrl:state.sharePosterImg,
          }
          setTimeout(()=>{
          posterShare(index,param)
          })
        }
      }
      else if(index.name === "复制链接"){
        getArticleDetailsUrl(state.articleId).then((res)=>{
          console.log("",res)
          copyStr(res)
          Toast.success("复制成功")
          state.showShare = false
        })
      }else if(index.name === "分享海报"){

        if(state.showSharePoster===true){
          Toast.loading({
            message: '生成海报中...',
            forbidClick: true,
            duration:0,
            className:'posterToast',
          });
          state.shareOptions = state.posterOptions
          // state.showImg = true
          state.showOverlay = true;
          let arr = []
          arr.push(state.posterPic)
          arr.push(state.qrCodeUrl)
          arr.push(state.headImgUrl)
          loadImages(arr,loadPoster)
          // setTimeout(()=>{
          //   savePoster()
          // },300)
        }
      }else {
        reprint({ busiId: state.articleId });
        let shareType;
        let content;
        let merchantId = localStorage.getItem('merchantId')
        if (index.name === "微信") {
          shareType = "shareToMiniProgram";
          content = `${process.env.VUE_APP_WECHAT_SHARE}/weChatShare/${state.articleId}/1/${merchantId}`;
        } else if (index.name === "朋友圈") {
          shareType = "shareH5PageToTimeLine";
          content = `${process.env.VUE_APP_WECHAT_SHARE}/weChatShare/${state.articleId}/2/${merchantId}`;
        }
        console.log(state.article);
        let param = {
          miniProgramType:state.article.jumpApplets,
          shareType: shareType,
          urlEntity: {
            title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
            content: content,
          },
          title: state.article.title,
          shareImg: state.article.smallCoverPic
              ? `${process.env.VUE_APP_WATCH_FILE_API}/${state.article.smallCoverPic}`
              : 'https://wca.ujiaku.com/upload/app_defaultImg/article-default.png',
        };
        flutterShowToast(param);
      }

    };
    //画图
    const savePoster = () =>{
      console.log("保存")
      state.showOverlay = true;
      // html2canvasChange()
      nextTick(() => {
        // var canvas2 = document.createElement("canvas");
        let _canvas = imageWrapper.value;
        const box = window.getComputedStyle(_canvas);
        // DOM 节点计算后宽高
        const width = parseInt(box.width, 10);
        const height = parseInt(box.height, 10);
        // 获取像素比
        const scaleBy = DPR();
        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        html2canvas(_canvas, {
          // scale:3,
          useCORS: true,
          background: "#ffffff",
          height: document.getElementById('saveposter').scrollHeight,
          windowHeight: document.getElementById('saveposter').scrollHeight,
          canvas,
        }).then((canvas) => {
          var context = canvas.getContext("2d");
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          let dataURL = canvas.toDataURL("image/png");
          state.showImg = false
          nextTick(() => {
            cardImgRef.value.style.width = width + "px";
            cardImgRef.value.style.height = height + "px";
            state.imgSrc = dataURL;
            state.showImg = false
          });
        });
      });
    }
    const DPR = () => {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
      }
      return 1;
    };

    //查找文章封面图
    searchFileList(state.fileParams).then((data) => {
      if (data[0]) {
        state.article.image = `${process.env.VUE_APP_WATCH_FILE_API}/${data[0].url}`;
        state.coverImage[0] = `${process.env.VUE_APP_WATCH_FILE_API}/${data[0].url}`;
      } else {
        state.article.image = state.morenImg;
        state.coverImage[0] = state.morenImg;
      }
    });
    const handleAtricle = (handle) => {
      if (handle === "editor") {
        router.push({
          path: "/articleVisitors/visitorEditor",
          query: {
            id: route.query.id,
            myType: route.query.myType,
            twoType: route.query.twoType,
          },
        });
      } else if (handle === "share") {
        // router.push({path:`/weChatShare/${state.articleId}`})
        getUserCardByPoster().then(res=>{
          if (res){
            if (res.headImgUrl){
              state.headImgUrl = state.imgUrl+'/'+res.headImgUrl
            }else {
              state.headImgUrl = require("@/assets/poster_headerImg_default.png")
            }
            state.userForm = res
            state.userTags = res.impressionLabel.split(',')
            if (state.userTags.length>4){
              state.userTags = state.userTags.slice(0,4)
            }
          }
        })
        state.shareOptions = state.options
        state.showShare = true;
        let params = {
          sourceId:state.articleId,//分享的id
          sourceType:1,//来源类别 1-文章 2-案例 3-视频 4-工地
        }
        getQrCodeUrl(params).then((res)=>{
          state.shareJumpUrl = res.jumpUrl
          if(res.qrCodeUrl){
            state.qrCodeUrl = res.qrCodeUrl
          }
          state.showSharePoster = true
        })
      }
    };
    const handleBlack = () => {
      if(route.params.addPoster==="true"){
        router.push({
          path:'/articleVisitors',
          name:'articleVisitors',
          params:{
            // fromPoster:true,
            addPoster:true
          }
        })
      }else if(route.params.fromPoster==="true"){
        router.push({
          path:'/articleVisitors',
          name:'articleVisitors',
          params:{
            fromPoster:true,
            // addPoster:true
          }
        })
      }else {
        if(route.query.from === 'card'){
          router.back()
        }else{
          if (route.query.isSearch === "isSearch") {
            router.push({
              path: "/articleManagement/search",
              query: {
                oneType: route.query.oneType,
                twoType: route.query.twoType,
                artType: route.query.artType,
                myType: route.query.myType,
                searchVal: route.query.searchVal,
              },
            });
          } else {
            router.push({
              name: "articleVisitors",
              params: {
                oneType: route.query.myType,
                twoType: route.query.twoType,
                typesStr: route.query.typesStr,
              },
            });
          }
        }
      }

    };
    return {
      loadPoster,
      imageWrapper,
      cardImgRef,
      savePoster,
      DPR,
      loadCuleSDetail,
      handleIsFold,
      container,
      onSelect,
      handleAtricle,
      handleBlack,
      ...toRefs(state),
      showHeader,
      loadCoverImage,
      loadUserCard,
      handleStarOrCollect,
      closeReply,
      getLeavingMsgAllReply,
      loadMore,
      handleDeleteMsg,
      handleReplyInfo,
      saveLeaveMsg,
      handleOpenLeavingMsg
    };
  },
};
</script>
<style lang="scss">
.posterToast{
  //color: #289fff;
  z-index: 1000000001 !important;
}
.visitorsArticlesDetails_conatiner {
  font-size: 16px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
  }
  .handle-article {
    position: fixed;
    right: 0;
    bottom: 110px;
    z-index: 100;
    div {
      height: 28px;
      width: 54px;
      color: #ffffff;
      font-size: 12px;
      opacity: 0.6;
      border-radius: 50px 0 0 50px;
      margin: 10px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    div:nth-child(1) {
      background: #ff6e01;
    }
    div:nth-child(2) {
      background: #ff6e01;
    }
  }
  .article-details {
    flex: 1;
    overflow-x: hidden;
    color: rgba(0, 0, 0, 0.56);
    .van-tabs__line{
      background-color: #ff6e01;
    }
    .name-card {
      background-color: #ffff;
      margin: 0 16px;
      margin-bottom: 20px;
      padding: 0 16px;
      box-shadow: 0px 5px 15px rgb(229, 229, 230);
      border-radius: 8px;
      p {
        padding: 0;
        margin: 0;
      }
      .name-info {
        display: flex;
        text-align: left;
        font-size: 14px;
        color: #333333;
        padding: 8px 0;
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 20px;
          object-fit: cover;
        }
        .creater-info {
          margin-left: 8px;
          > p:nth-child(1) {
            font-weight: 600;
          }
          > p:nth-child(2) {
            font-size: 12px;
            color: #666666;
          }
        }
      }
      .synopsis {
        text-align: left;
        font-size: 12px;
        color: black;
        padding-bottom: 5px;
      }
    }
    .artcile-base-info {
      background-color: #ffffff;
      padding-top: 12px;
      .article_title {
        padding: 0 16px;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.84);
        margin-bottom: 5px;
        word-wrap: break-word;
      }
      .article_createtime {
        padding: 10px 16px;
        text-align: left;
        font-size: 14px;
      }
    }
    .cover-img {
      padding: 5px 16px;
      height: 160px;
      background-color: #fff;
      .img-box {
        height: 160px;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .artcle-content {
      width: 100%;
      padding: 0 16px;
      font-size: 14px;
      text-align: left;
      box-sizing: border-box;
      background-color: #ffffff;
      .artcle-content-box {
        img {
          max-width: 100%;
        }
        p {
          margin: 0;
          padding: 0;
        }
        border-bottom: 1px solid #f1f3f6;
      }
    }
    .star-collection {
      height: 34px;
      font-size: 14px;
      color: #333333;
      background-color: #ffffff;
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      //> div {
      //  display: flex;
      //  align-items: center;
      //}
      .left{
          display: flex;
          align-items: center;
        > div {
          display: flex;
          align-items: center;
        }
        .collection{
          margin-left: 30px;
        }
      }
      .leaving-botton-ts {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #000000;
        font-weight: 700;

        > button {
          width: 72px;
          height: 30px;
          border: none;
          color: white;
          border-radius: 5px;
          //margin-right: 29px;
          background-color: #289fff;
        }

        .button-bc {
          background-color: #bfbfbf;
        }
      }
    }
    .relation_cules {
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      padding: 16px;
      color: rgba(0, 0, 0, 0.84);
    }
    .leav_message {
      height: 100px;
      background-color: #9999;
    }
    .related-clues {
      text-align: left;
      padding: 5px 16px;
      //background: #f7f7f7;
      .no-clues{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        //height: 85vh;
        //font-size: 24px;
        //font-weight: 500;
      }
      .van-cell {
        margin-bottom: 10px;
      }
      > p {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 600;
      }
      .clues-box {
        .img-name {
          height: 40px;
          padding: 5px 0;
          img {
            display: block;
            height: 40px;
            width: 40px;
            border-radius: 20px;
            float: left;
          }
          .creater-info {
            float: left;
            margin-left: 10px;
            font-size: 14px;
            text-align: left;
            p {
              margin: 0;
            }
            > p:nth-child(1) {
              font-weight: 600;
            }
            > p:nth-child(2) {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
        .clues-details {
          background-color: #f7f7f7;
          padding: 5px;
          > p {
            margin: 0;
            font-size: 12px;
          }
        }
      }

    }
  }
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
  .wrapperPoster{
    //margin-top: 100px;
    height: 400px;
    overflow-x: hidden;
    //position: absolute;
    position: fixed;
    top: 60px;
    //bottom: 0;
    left: 0;
    right: 0;
    .poster-details{
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 250px;
      overflow-x: hidden;
      >img{
        width: 250px;
        display: block;
      }
      .text-qrcode{
        background-color: #ffff;
        display: flex;
        justify-content: space-between;
        padding: 12px;
        flex: 1;
        .user-info-tag{
          .user-info{
            display: flex;
            align-items: center;
            img{
              border-radius: 20px;
              width: 40px;
              height: 40px;
              object-fit: cover;
              flex-shrink: 0;
            }
            .left-text{
              text-align: left;
              //flex: 1;
              margin-left: 4px;
              p {
                margin: 0;
                padding: 0;
              }
              p:nth-child(1){
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                color: #333333;
              }
              p:nth-child(2){
                font-size: 10px;
                color: #333333;
                //line-height: 20px;
              }
            }
          }
          .user-tag{
            display: flex;
            flex-wrap: wrap;
            .user-tag-item{
              margin-top: 5px;
              margin-right: 5px;
              //padding-left: 5px;
              padding:2px 5px;
              color: #289FFF;
              background-color: #E6ECFF;
              font-size: 9px;
              border-radius: 4px;
            }
          }
        }


        .qrCodeUrl-all{
          //position: relative;
          //text-align: center;
          //height: 60px;
          border-left: 1px solid #F1F3F6;
          padding-left: 18px;
          margin-left: 8px;
          flex-shrink:0;
          .qrCodeUrl-cls{
            //width: 60px;
            //height: 60px;
            background-color: #FFFFFF;
            border-radius: 30px;
            //position: relative;
            //top: -33px;
            //text-align: center;
            img{
              width: 60px;
              //border-radius: 25px;
              //position: relative;
              //margin-top: 50%;
              //transform: translateY(-50%);
            }
          }
          >p{
            //position: relative;
            text-align: center;
            font-size: 10px;
            font-weight: 400;
            color: #666666;
            margin: 0;
            //top: -40px;
          }
        }
      }

    }
    .posterImg-hb{
      height: 400px;
      overflow-x: hidden;
    }
  }

  .leave-message-box {
    //  border-bottom: .5px solid #F1F3F6;
    border-top: 0.5px solid #f1f3f6;
    background-color: #ffff;
    padding: 12px 0;
    .user-info {
      background-color: #ffff;
      border-bottom: none;
      padding: 0 16px;
      .img-content{
        display: flex;
        img{
          height: 40px;
          width: 40px;
          border-radius: 20px;
          object-fit: cover;
        }
        .name-content{
          text-align: left;
          margin-left: 10px;
          padding-top: 5px;
          .name{
            font-size: 14px;
            color: #333333;
            font-weight: 600;
          }
          .content{
            font-size: 14px;
            color: #666666;
            padding: 6px 0;
          }
        }
      }

    }
    .margin-cls{
      margin-left: 45px;
    }
    .add-margin{
      margin-left: 60px;
    }
    .leave-message-time {
      right: -180px;
      font-size: 12px;
      text-align: left;
      margin-left: 27px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      padding-bottom: 5px;
      justify-content: space-between;
      .icon-cls{
        font-size: 16px;
        color: black;
        margin-right: 20px;
      }
      .time{

      }
      .reply{
        margin-left: 8px;
        color:#289FFF;
      }
    }
    .leave-message-time-child {
      right: -180px;
      font-size: 12px;
      text-align: left;
      margin-left: 65px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-cls{
        font-size: 16px;
        color: black;
        margin-right: 20px;
      }
      .time{

      }
      .reply{
        margin-left: 8px;
        color:#289FFF;
      }
    }
    .leave-message-time-child {
      right: -180px;
      font-size: 12px;
      text-align: left;
      display: flex;
      align-items: center;
      .icon-cls{
        font-size: 16px;
        color: black;
        margin-right: 20px;
      }
      .time{

      }
      .reply{
        margin-left: 8px;
        color:#289FFF;
      }
    }
    .synopsis {
      font-size: 14px;
      color: #666666;
      line-height: 22px;
    }
  }
  .create-leave-message {
    position: fixed;
    //z-index: 999;
    bottom: 0;
    //border-top: 0.5px solid #f1f3f6;
    width: 375px;

    .leave-message-time {
      position: relative;
      right: -200px;
    }

    .synopsis {
      .van-cell::after {
        border: none;
      }

      .van-cell {
        // padding-right: 0;
        // padding-left: 40px;
        padding: 0 0 0 40px;
      }

      .van-field__control {
        border: 0.25px solid #bfbfbf;
        padding: 0;
      }

      .cancel-save {
        height: 53px;
        line-height: 53px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        // border: 1px solid #ffffff;
        .van-button {
          height: 28px;
          width: 72px;
          border-radius: 5px;
          font-size: 12px;
          margin-left: 15px;
        }
      }
    }
  }
}
</style>
