import {get, put,del} from '../../utils/request'

/**
 * 获取获客文章列表
 */
export function getArticleList(params){
    return get(
        '/article/findArticleByPageApp',
        params,      
    )
}
/**
 * 获取文章管理列表
 */
export function getManageArticleList(params){
    return get(
        '/article/findArticleByPageManageApp',
        params,
    )
}
/**
 * 删除文章
 */
export function deleteArticleMana(id){
    return del(
       `/article/deleteArticle/${id}`,
    )
}
/**
 * 根据id查找文章
 */
export function getArticleById(id){
    
    return get(
        `article/findArticleByIdApp/${id}`,
    )
}

/**
 * 修改文章
 */

export function updateArticleMana(params){
    return put(
        `/article/updateArticle`,
        params
    )
}
/**
 * 获取文章分类
 */

export function findArticleType(){
    return get(
        `/article/findArticleType`,
    )
}
/**
 * 获取素材库分类
 */

export function findMaterial(){
    return get(
        `/article/findMaterialApp`,
    )
}
/**
 * 获取当前公司所属素材库中有内容的分类
 */

export function getMerchantMaterialFullCategory(params){
    return get(
        `/user/material/getMerchantMaterialFullCategory`,
        params,
    )
}
/**
 * 根据素材库id获取素材库类别以及本身名字
 * @param {*} param
 */
export function getMaterialAndCategory(params){
    return get(
        '/user/material/getMaterialAndCategory',
        params,
        true,
    )
}

