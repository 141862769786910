//微信分享文章案例视频工地的海报
import { uploadCoverPic} from "@/api/file/index";
import {flutterShowToast} from "@/utils/appChannel";
/**
 * 微信分享
 * */
export const posterShare = async (index,state) =>{
    let posterFile = await dataURLtoFile(state.imgSrc,'png')
    // console.log("海报图片",posterFile)
    if(posterFile){
        let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
        formData.append("file", posterFile); //接口需要传的参数
        formData.append("busiId", state.id); //接口需要传的参数
        formData.append("busiCat", "wxShare"); //接口需要传的参数
        formData.append("groupId", "posterImg"); //接口需要传的参数
        uploadCoverPic(formData)
            .then((data) => {
                // let item = {
                //     url: `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`,
                //     fileId: data.fileId,
                // };
                // console.log("上传后的海报地址===============",item.url);
                let shareType;
                let shareImg = state.bigPosterImgUrl;//缩略图
                let content = `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`;//图片地址
                if(index.name=== "微信分享"){
                    shareType = "shareImageToWeiXin"
                }else  if(index.name === "朋友圈分享"){
                    shareType = "shareImageToTimeLine"
                }
                let params = {
                    shareType:shareType,
                    shareImg:shareImg,
                    urlEntity:{
                        content:content
                    }
                }
                console.log(params);
                flutterShowToast(params);
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
//base64转文件
const dataURLtoFile=(dataurl, filename)=> {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}