import { post,get } from '@/utils/request';
export function reprint(param) {
    return post(
        `/article/reprint`,
        param
    );
}

export function caseReprint(param) {
    return post(
        `/case/reprint`,
        param
    );
}
export function buildReprint(param) {
    return post(
        `/case/build/reprint`,
        param
    );
}
export function getWxJsSign(param) {
    return get(
        `/user/wx/getWxJsSign`,
        param
    );
}

//分享完创建海报
export function createPosterAppShare(param) {
    return post(
        `/poster/createPosterAppShare`,
        param
    );
}
//获取分享的二维码
export function getQrCodeUrl(param) {
    return post(
        `/poster/getQrCodeUrl`,
        param
    );
}
