import {get,del} from '../../utils/request'
/**
 * 根据id查找文章
 */
export function getArticleById(id,openId){

    return get(
        `article/findArticleByIdWeChat/${id}/${openId}`,
    )
}

/**
 * 获取热门文章列表
 */
export function findHotArticle(num){
    return get(
        `/article/findHotArticle/${num}`,
    )
}
/**
 * 分页查询文章留言
 */
export function findArticleLeavingMsgByPage(params){
    return get(
        `/article/findArticleLeavingMsgByPage`,
        params,
    )
}
/**
 * 查询留言所有子回复
 */
export function findLeavingMsgAllReply(params){
    return get(
        `/article/findLeavingMsgAllReply`,
        params,
    )
}
/**
 * 删除留言或者回复
 */
export function deleteLeavingMsgReply(id){
    return del(
        `/article/deleteLeavingMsgReply/${id}`,
    )
}
