<template>
  <div class="html-text-cls" id="editorId">
    <div class="custome-editor"  v-html="contents"  @click="showImg($event)"  @touchstart="getImages"></div>
  </div>
</template>

<script>
import {reactive, toRefs} from "vue";
import {ImagePreview} from "vant";
import {flutterShowToast} from "@/utils/appChannel";

export default {
  props: {
    contents: {
      type: String,
      default: "",
      downImgUrl:'',
      state:'',
    },
  },
  setup(props,{emit}) {
  // setup() {
    const state = reactive({
      showOverlay:false,
      show:false,
      img:'',
      time:'',
      saveImgUrl:'',
      openReview:true,
      actions: [{ name: '选项一' }, { name: '选项二' }, { name: '选项三' }],
    })

    const showImg = (e) =>{
      state.show = true
      // if (sImg){
        // document.getElementsByTagName('body')[0].removeChild(sImg);
        // document.getElementById('editorId').removeChild(sImg)
        // body[0].removeChild(sImg)
        // console.log(editor,sImg)
      // }else {
        if (e.target.tagName == 'IMG') {
          let body = document.getElementsByTagName('body')
          let span = document.createElement("div")
          span.id = 'RImg'
          span.style.fontSize = '16px'
          span.style.position =  'absolute'
          span.style.top = "85%"
          span.style.left = "50%"
          span.style.transform = 'translateX(-50%)';
          span.style.display = 'block'
          span.style.width = '100px'
          span.style.textAlign = 'center'
          span.style.padding = '5px 10px'
          span.style.borderRadius = '4px'
          span.style.color = '#ffffff'
          span.style.border= "1px solid #ffffff";

          // span.style.background = '#ffffff'
          span.style.zIndex = '9999'
          // span.style.opacity = "0.5"
          span.innerText = '保存图片'
          span.onclick = saveReviewImg
          body[0].appendChild(span)
          state.img = e.target.src
          ImagePreview({
            images:[state.img],
            showIndex: false,
            onClose(){
              console.log("关闭预览");
              const RImg = document.getElementById('RImg')
              document.getElementsByTagName('body')[0].removeChild(RImg)
            }
          });
        }
      // }
    }
    const saveReviewImg = ()=>{
      let params = {
        shareType:"saveBase64Image",
        desc:'',
        urlEntity:{
          content:state.img
        }
      }
      flutterShowToast(params)
    }
    const onCancel = () =>{

    }
    const getImages = () => {
      // let system = isAndroidOrIOS()
      // console.log(system)
      let ua = navigator.userAgent.toLowerCase();
      let isNotWeixin = ua.indexOf('micromessenger')===-1
      console.log(isNotWeixin)
      if(isAndroidOrIOS()!=='ios'&&isNotWeixin){
        window.time = 0;
        let time = ''
        var objs = document.getElementsByTagName('img');
        for(var i=0; i<objs.length;i++) {
          objs[i].addEventListener('touchstart',function(e) {
            e.stopPropagation();
            time = setTimeout(function() {
              //这里写需要执行操作的代码
              state.show = true

              emit('set-height','来自子组件')
              state.showOverlay = true
              //删除先前生成的标签
              // const editor = document.getElementById('editorId')
              let body = document.getElementsByTagName('body')
              const sImg = document.getElementById('sImg')
              if (sImg){
                document.getElementById('editorId').removeChild(sImg)
              }
              let mask = document.createElement('div')
              mask.style.width = '100vw'
              mask.id = 'mask'
              mask.style.height = '100vh'
              mask.style.zIndex = '110'
              mask.style.backgroundColor = 'black'
              mask.style.position = 'absolute'
              mask.style.top = '0px'
              mask.style.opacity = '0.5'
              body[0].appendChild(mask)
              mask.onclick = cancelSavePressImg
              console.log("长按图片",e.touches[0])


              let span = document.createElement("div")
              span.id = 'sImg'
              span.style.fontSize = '16px'
              span.style.position =  'absolute'
              span.style.top = "50%"
              span.style.left = "50%"
              span.style.transform = 'translateX(-50%)';
              span.style.display = 'block'
              span.style.width = '100px'
              span.style.textAlign = 'center'
              span.style.padding = '5px 10px'
              span.style.borderRadius = '4px'
              span.style.color = '#000000'
              span.style.background = '#ffffff'
              span.style.zIndex = '111'
              // span.style.opacity = "0.5"
              span.innerText = '保存图片'
              span.onclick = savePressImg
              body[0].appendChild(span)
              state.saveImgUrl = e.touches[0].target.currentSrc
              console.log("图片",state.saveImgUrl)
            }, 500); //这里设置长按响应时间
          })
          objs[i].addEventListener('touchend',function(e) {
            e.stopPropagation();
            clearTimeout(time);
          })
        }
      }
    }
    const  isAndroidOrIOS=()=>{
      const u = navigator.userAgent;
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        return "android"
      }
      if (isiOS) {
        return "ios"
      }
      return false
    }
    const savePressImg = ()=>{
      // getBase64(state.saveImgUrl,dataUrl=>{
      //   // console.log('按压图片',dataUrl)
      //   let params = {
      //     shareType:"saveBase64Image",
      //     desc:dataUrl,
      //     urlEntity:{
      //       content:state.saveImgUrl
      //     }
      //   }
      //   flutterShowToast(params)
      // })
      let params = {
        shareType:"saveBase64Image",
        desc:'',
        urlEntity:{
          content:state.saveImgUrl
        }
      }
      flutterShowToast(params)
      // const editor = document.getElementById('editorId')
      const sImg = document.getElementById('sImg')
      const mask = document.getElementById('mask')
      // if (sImg){
        // document.getElementById('editorId').removeChild(sImg)
        document.getElementsByTagName('body')[0].removeChild(sImg)
        document.getElementsByTagName('body')[0].removeChild(mask)
      // }
    }
    const cancelSavePressImg = ()=>{
      const sImg = document.getElementById('sImg')
      const mask = document.getElementById('mask')
      document.getElementsByTagName('body')[0].removeChild(sImg)
      document.getElementsByTagName('body')[0].removeChild(mask)
    }
    // const getBase64 = (url, callback)=>{
    //   var Img = new Image(),
    //       dataURL = '';
    //   Img.src = url + '?v=' + Math.random();
    //   Img.setAttribute('crossOrigin', 'Anonymous');
    //   Img.onload = function() {
    //     var canvas = document.createElement('canvas'),
    //         width = Img.width,
    //         height = Img.height;
    //     canvas.width = width;
    //     canvas.height = height;
    //     canvas.getContext('2d').drawImage(Img, 0, 0, width, height);
    //     dataURL = canvas.toDataURL('image/jpeg');
    //     return callback ? callback(dataURL) : null;
    //   };
    // }
    return {
      onCancel,
      getImages,
      // start,
      showImg,
      ...toRefs(state)
    };
  },
};
</script>

<style lang="scss">
.html-text-cls{
  //width: 100vw;
  //height: 100vh;
  //.wrapper {
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  height: 100%;
  //}
  //.block {
  //  width: 100px;
  //  height: 50px;
  //  background-color: #fff;
  //}
}
//.define-wapper{
//  height: 100vh;
//  width: 100vw;
//  background-color: #289fff;
//  z-index: 9999999;
//  position: absolute;
//}
.custome-editor {
  width: 100%;
  word-wrap: break-word;
  position: relative;
  -webkit-touch-callout: default;
  p{
    height: auto;
    margin-top: 0!important;
    margin-bottom: 0!important;
    padding: 0;
  }
  img {
    max-width: 100% !important;
    //display: block;
    object-fit: contain !important;
    vertical-align: middle;
    margin: 0;
    padding: 0;
  }
  //富文本图片放大
  .imgDolg {
    width: 100vw;
    height: 100vh;
    //position: fixed;
    position: absolute;
    z-index: 9999;
    background-color: rgba(140, 134, 134, 0.6);
    //top: 0;
    //left: 0;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    #imgDolgClose {
      position: fixed;
      top: 35px;
      cursor: pointer;
      right: 7%;
      font-size: 50px;
      color: white;
    }
    img{
      width: 80%;
    }
  }
}
</style>
